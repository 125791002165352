<template>
  <div
    class="container-xs"
    style="display: flex; flex-direction: column; height: 100%"
  >
    <AppHeader back-to="/profile/edit" />
    <div
      style="
        margin-top: 2rem;
        display: grid;
        gap: 1.5rem;
        text-align: center;
        justify-items: center;
      "
    >
      <IconWarning />
      <p
        style="
          font-size: 14px;
          font-family: LuciferSans;
          text-transform: uppercase;
        "
      >
        Are you sure you want to Delete Your Account?
      </p>
      <p style="font-size: 14px">
        Deleting your account is permanent. Your profile and data will be
        permanently deleted.
      </p>
      <x-button type="large" style="margin-top: 3rem" @click="openDeleteModal">
        {{ $t('form.delete-account') }}
      </x-button>
    </div>
    <InfoModal
      ref="deleteAccountModal"
      text="This cannot be undone after confirming."
      title="Are you sure you want to Delete your account?"
      cta="Yes, I'm Sure"
      alt-cta="No, I'll keep my account"
      hide-close-button
      @alt-cta="$router.go(-1)"
      @cta="deleteAccount"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppHeader from '../../components-rf/AppHeader.vue'
import IconWarning from '../../components-rf/icons/common/IconWarning.vue'
import InfoModal from '../../components-rf/modals/InfoModal.vue'

export default {
  name: 'EditProfilePassword',
  components: { IconWarning, InfoModal, AppHeader },
  methods: {
    openDeleteModal() {
      this.$refs.deleteAccountModal.show()
    },
    deleteAccount() {
      this.$store.dispatch('UserModule/deleteUser')
    },
  },
  computed: mapState('UserModule', ['user']),
}
</script>

<style scoped lang="scss">
.x-label-text {
  font-size: 0.5625rem;
  color: var(--color-grey);
}
</style>
