import { render, staticRenderFns } from "./EditProfileDeleteAccount.vue?vue&type=template&id=7c5cf298&scoped=true"
import script from "./EditProfileDeleteAccount.vue?vue&type=script&lang=js"
export * from "./EditProfileDeleteAccount.vue?vue&type=script&lang=js"
import style0 from "./EditProfileDeleteAccount.vue?vue&type=style&index=0&id=7c5cf298&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c5cf298",
  null
  
)

export default component.exports